import React, { useState } from 'react';
import data from "../data";
import { Modal, Button } from "react-bootstrap";

const TeamTwo = ({ item, teamStyle = "" }) => {
    const [selectedMembers, setSelectedMembers] = useState([]);

    const openModal = (member) => {
        setSelectedMembers(prevState => [...prevState, member]);
    };

    const closeModal = () => {
        setSelectedMembers([]);
    };
    

    return (
        <>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 justify-content-center">
                {data.slice(0, item).map((member, i) => (
                    <div className="col" key={i}>
                        <div className={`team-static ${teamStyle}`} style={{ cursor: 'pointer' }}>
                            <div className="_thumbnail" onClick={() => openModal(member)}>
                                <img src={`/assets/images/team/${member.images}.png`} alt={member.title} className="img-fluid mb-4" />
                                <h5>{member.title}</h5>
                                <h6>{member.designation}</h6>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Fullscreen Modal for Selected Team Members */}
            {selectedMembers.length > 0 && (
                <Modal show={true} onHide={closeModal} centered fullscreen dialogClassName="modal-dialog modal-xl modal-dialog-centered" backdrop="static"  >
                    <Modal.Header closeButton>
                        <Modal.Title>Team Detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                {selectedMembers.map((member, i) => (
                                    <div className="col-md-12 mb-4" key={i}>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <img 
                                                    src={`/assets/images/team/${member.images}.png`} 
                                                    alt={member.title} 
                                                    className="img-fluid rounded" 
                                                    style={{ maxHeight: '400px' }}
                                                />
                                            </div>

                                            <div className="col-lg-8 col-md-6 col-sm-12">
                                                <h4>{member.title}</h4>
                                                <h5>{member.designation}</h5>
                                                {/* <p className='px-4'>{member.description}</p> */}
                                                <p dangerouslySetInnerHTML={{ __html: member.description }} />
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>Close</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default TeamTwo;
