import React from "react";
import { MdHighQuality, MdVerifiedUser } from "react-icons/md";
import { GiStarsStack, GiWallet } from "react-icons/gi";
import { FaHandshake, FaShippingFast } from "react-icons/fa";

// ServiceList data
const ServiceList = [
  {
    icon: <MdHighQuality />,
    title: 'EXTENSIVE PRODUCT RANGE',
    description:
      'We have extensive product range of LV/MV, PVC/ XLPE Power and Control Cables, LV/MV Elastomeric (Rubber) Cables, Instrumentation Cables, Automotive Cables, Enamelled Copper Wires, Submersible Wires, HFFR/LSZH Cables, UG/OCP Mining Cables and wind turbine cables.',
  },
  {
    icon: <GiStarsStack />,
    title: 'QUALITY',
    description:
      'Customers will choose a product that meets their expectation in terms of material quality, durability, functionality, safety and so on. At BMI we offer a high quality product that ensures customer satisfaction and inbuilt brand credibility.',
  },
  {
    icon: <FaHandshake />,
    title: 'RELIABILITY',
    description:
      'At BMI our customers feel confident in their investment, knowing that they are getting a product that is durable, safe and consistent with their requirements. It builds trust and loyalty between our customers and the business.',
  },
  {
    icon: <MdVerifiedUser />,
    title: 'CUSTOMER SERVICE',
    description:
      'Customer Service is a crucial aspect of any successful business. At BMI our priority is always to work towards customer satisfaction, and for the same, we have a dedicated customer service team that provides us with a differentiated experience.',
  },
  {
    icon: <GiWallet />,
    title: 'AFFORDABILITY',
    description:
      'Affordability is a critical aspect that customers consider when purchasing products. At BMI we emphasize competitive pricing and offer good value to customers at a reasonable price.',
  },
  {
    icon: <FaShippingFast />,
    title: 'DELIVERY',
    description:
      'Today customers value timely delivery of their orders, and BMI offers fast and efficient service, comprehensive coverage, and safe, secure shipping.',
  },
];


const ServiceThree = ({ column, item }) => {
  const ServiceContent = ServiceList.slice(0, item);

  return (
    <div className="row justify-content-center">
      {ServiceContent.map((val, i) => (
        <div className={column} key={i}>
          <div className="service service__style--2">
            <div className="icon">{val.icon}</div>
            <div className="content">
              <h3 className="title">{val.title}</h3>
              <p>{val.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServiceThree;
