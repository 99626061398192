/*
 * IMPORT
 */
import React, { Component } from "react";
import { FiHeadphones, FiMail, FiMapPin, FiChevronUp } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";

/*
 * COMPONENT
 */
import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";
import ContactTwo from "../elements/contact/ContactTwo";
import PageHelmet from "../component/common/Helmet";

/*
 * Class
 */
class Contact extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  };

  render() {
    // Background image URL
    const bgImageUrl = "/assets/images/banner/contacts.png"; // Replace with your background image path

    return (
      <React.Fragment>
        <PageHelmet pageTitle="Contact" />

        <HeaderThree
          contact
          homeLink="/"
          logo="symbol-light"
          color="color-black"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190"
          style={{
            backgroundImage: `url(${bgImageUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            position: "relative",
            color: "#fff",
          }}
        >
          <div
            className="overlay"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.6)", // Overlay for better text contrast
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          ></div>
          <div className="container" style={{ position: "relative", zIndex: 1 }}>
            <div className="row">
              <div className="col-lg-6 m-auto">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Contact Us</h2>
                  {/* <p className="welcomeDescription">
                    Reach out to us for any inquiries or support.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Contact Top Area */}
        <div className="rn-contact-top-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              {/* Contact Information */}
              {[
                {
                  icon: <FiHeadphones />,
                  title: "Contact With Phone Number",
                  details: [
                    { href: "tel:+91-11-46032371", text: "+91-11-46032371" },
                    // { href: "tel:+91-11-46032372", text: "+91-11-46032372" },
                  ],
                },
                {
                  icon: <FiMail />,
                  title: "Email Address",
                  details: [
                    { href: "mailto:sales1@bmicables.com", text: "sales1@bmicables.com" },
                    // { href: "mailto:marketing@bmicables.com", text: "marketing@bmicables.com" },
                  ],
                },
                {
                  icon: <FiMapPin />,
                  title: "Corporate Office",
                  details: [
                    "406, Bhikaji Cama Bhawan, Bhikaji Cama Place, New Delhi-110066",
                  ],
                  link: "https://www.google.com/maps?q=28.5691601,77.1870646&z=17&hl=en",
                },
                {
                  icon: <FiMapPin />,
                  title: "Works",
                  details: [
                    "F-81 to 84, & G-108, RIICO Industrial Area, Kushkhera, Bhiwadi, Distt. Alwar, Rajasthan-301019",
                  ],
                  link: "https://www.google.com/maps?q=28.1171646,76.7874624&z=17&hl=en",
                },
              ].map((info, index) => (
                <div
                  className={`col-lg-6 col-md-6 col-sm-6 col-12 ${
                    index >= 2 ? "mt-5 mt_md--50 mt_sm--50" : ""
                  }`}
                  key={index}
                >
                  <div className="rn-address">
                    {info.link ? (
                      <a href={info.link} target="_blank" rel="noopener noreferrer">
                        <div className="icon">{info.icon}</div>
                      </a>
                    ) : (
                      <div className="icon">{info.icon}</div>
                    )}
                    <div className="inner">
                      <h4 className="title">{info.title}</h4>
                      {info.details.map((detail, idx) =>
                        typeof detail === "string" ? (
                          <p key={idx}>{detail}</p>
                        ) : (
                          <p key={idx}>
                            <a href={detail.href} rel="noopener noreferrer">
                              {detail.text}
                            </a>
                          </p>
                        )
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Contact Top Area */}

        {/* Start Contact Page Area */}
        <div className="rn-contact-page ptb--120 bg_color--1">
          <ContactTwo />
        </div>
        {/* End Contact Page Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}

/*
 * EXPORTS
 */
export default Contact;
