// /*
//  * IMPORTS
//  */
// import React , {useState} from 'react';
// import emailjs from 'emailjs-com';

// /*
//  * Const Assignment
//  */
// const Result = () => {
//     return (
//         <p className="success-message">Your Message has been successfully sent. I will contact you soon.</p>
//     )
// }
// function ContactForm({props}) {
//     const [ result,showresult ] = useState(false);

//     const sendEmail = (e) => {
//         console.log(e.target)
//         e.preventDefault();
//         emailjs
//             .sendForm(
//                 'service_p4x3hv8',
//                 'template_jgfr42f',
//                 e.target,
//                 'user_jrfTH2e0Ely35ZCVFdT9S'
//             )
//             .then((result) => {
//                     console.log(result.text);
//                 },
//                 (error) => {
//                     console.log(error.text);
//                 }
//             );
//         e.target.reset();
//         showresult(true);
//     };

//     setTimeout(() => {
//         showresult(false);
//     }, 5000);

//     return (
//         <form onSubmit={sendEmail}>
//             <div className="rn-form-group">
//                 <input
//                     type="text"
//                     name="fullname"
//                     placeholder="Your Name"
//                     required
//                 />
//             </div>

//             <div className="rn-form-group">
//                 <input
//                     type="email"
//                     name="email"
//                     placeholder="Your Email"
//                     required
//                 />
//             </div>

//             <div className="rn-form-group">
//                 <input
//                     type="text"
//                     name="phone"
//                     placeholder="Phone Number"
//                     required
//                 />
//             </div>

//             <div className="rn-form-group">
//                 <input
//                     type="text"
//                     name="subject"
//                     placeholder="Subject"
//                     required
//                 />
//             </div>

//             <div className="rn-form-group">
//                 <textarea
//                     name="message"
//                     placeholder="Your Message"
//                     required
//                 >
//                 </textarea>
//             </div>

//             <div className="rn-form-group">
//                 <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit Now</button>
//             </div>

//             <div className="rn-form-group">
//                 {result ? <Result /> : null}
//             </div>
//         </form>
//     )
// }
// /*
//  * EXPORT
//  */
// export default ContactForm;



import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Result = () => {
    return (
        <p className="success-message text-center mt-3">Your message has been successfully sent. We will contact you soon.</p>
    )
}

function ContactForm() {
    const [result, showResult] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(
            'service_p4x3hv8',
            'template_jgfr42f',
            e.target,
            'user_jrfTH2e0Ely35ZCVFdT9S'
        ).then(
            (result) => {
                console.log(result.text);
            },
            (error) => {
                console.log(error.text);
            }
        );
        e.target.reset();
        showResult(true);
        setTimeout(() => {
            showResult(false);
        }, 5000);
    };

    return (
        <form onSubmit={sendEmail}>
            <div className="row">
                <div className="col-md-6">
                    <div className="rn-form-group mb-3">
                        <input type="text" name="fullname" placeholder="Your Name" required />
                    </div>
                    <div className="rn-form-group mb-3">
                        <input type="email" name="email" placeholder="Your Email" required />
                    </div>
                    <div className="rn-form-group mb-3">
                        <input type="text" name="phone" placeholder="Phone Number" required />
                    </div>
                    <div className="rn-form-group mb-3">
                        <input type="text" name="subject" placeholder="Subject" required />
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="rn-form-group mb-3 _h-260px">
                        <textarea name="message" placeholder="Your Message" rows="8" required style={{ height: '260px' }}></textarea>
                    </div>
                </div>
            </div>

            <div className="text-center mt-4">
                <button className="rn-button-style--2 btn-solid" type="submit">Submit Now</button>
            </div>

            {result ? <Result /> : null}
        </form>
    );
}

export default ContactForm;
