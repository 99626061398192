const data = [
    {
        images: '2',
        title: 'SURESH BAUNTHIYAL',
        designation: 'Vice President, Sales & Marketing',
        description: 'With a rich experience spanning over three decades, Suresh Baunthiyal brings deep industry expertise and leadership to BMI Cables. Associate Member of the Institution of Engineers (A.M.I.E.) and a Diploma in Engineering (D.E.Engg.), he has held key roles across various organizations, excelling in both Quality and Sales & Marketing. His career includes serving as AGM Quality at Riyadh Cables, Saudi Arabia, and AEG Cables, Germany, before transitioning into sales and marketing leadership. As Vice President – Sales & Marketing at BMI Cables, he plays a pivotal role in driving customer-centric solutions, expanding market reach, and reinforcing the company’s commitment to excellence.',
    },
    {
        images: '5',
        title: 'ANURADHA KAKKAR',
        designation: 'AGM, Sales & Marketing ',
        description: 'Anuradha Kakkar is a skilled and dedicated professional with <b> 25 years of experience </b>  and a <b> PGDBM in Marketing </b> from <b> Symbiosis University, Pune.</b> With strong expertise in <b> client relations and revenue growth </b>, she has held senior-level positions in the cable industry, playing a pivotal role in business development and market expansion. <br /> <br /> Currently serving as <b> AGM – Sales at BMI Cables,</b> Anuradha excels in <b> driving revenue, solving complex challenges, and leading teams with strategic vision.</b> Her leadership and problem-solving abilities make her an invaluable asset to the organization.',
    },
    {
        images: '4',
        title: 'VIRENDER PANDITA',
        designation: 'AGM Business Development',
        description: 'With 15 years of dedicated service in the Indian Army as a Senior Technician, Virender Pandita brings a wealth of technical expertise, discipline, and problem-solving abilities to the team. Transitioning into the corporate sector, he has leveraged his leadership, analytical thinking, and technical skills to drive operational excellence and innovation. <br /><br /> Currently, as a valued member of BMI Cables, Virender continues to apply his military precision and technical proficiency to every challenge. His commitment to continuous learning and excellence makes him an indispensable asset to the organization.',
    },
    {
        images: '6',
        title: 'SUNIL GUPTA',
        designation: 'AGM Business Development',
        description: 'With an impressive 40 years of experience, Sunil Gupta is a seasoned professional with a strong background in Business Development. Over the years, he has held key leadership positions, showcasing expertise in business growth, client relations, and strategic decision-making. <br /> <br /> Throughout his career, Sunil has played a pivotal role in the success of renowned organizations, holding senior positions where he made significant contributions to business expansion, revenue optimization, and operational excellence. <br /><br /> With vast industry knowledge, exceptional problem-solving abilities, and strong leadership acumen, Sunil continues to drive BMI Cables forward, foster innovation, and strengthen its market presence.',
    },
];

export default data;


