/*
 * IMPORTS
 */
import React, { Component } from "react";
import { FiX, FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";


/*
 * Class
 */
class HeaderThree extends Component {
  // Constructor
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    this.state = {
      navLinks: [
        {
          title: "About Us",
          id: "aboutUs",
          type: [
            {
              name: "About Us",
              id: ''
            },
            {
              name: "Why Us",
              id: 'kl'
            },
            {
              name: "Our Team",
              id: 'our-team'
            },
            {
              name: "Certifications",
              id: 'certifications'
            },
            {
              name: "Customers",
              id: 'our-customers'
            },
          ],
        },
        {
          title: "Products",
          id: "products",
          type: [
            {
              name: "ELASTOMERIC (RUBBER) CABLE",
              titleSlug: 'elastomeric-cables',
              types: [
                {
                  name: "LT / MV Elastomeric (Rubber) Power & Control Cables",
                  slug: "ltmv-elastomeric-rubber-power-cables",
                },
                {
                  name: "Elastomeric (Rubber) Mining Cables",
                  slug: "mining-cables",
                },
                {
                  name: "Composite/Hybrid Cables",
                  slug: "composite-cables-up-to-11-kv",
                },
                {
                  name: "Silicon Rubber High Temprature Cables",
                  slug: "silicon-rubber-high-temperature-cables-power",
                },
                {
                  name: "Welding Cables",
                  slug: "welding-cables",
                },
              ],
            },
            {
              name: "POWER, CONTROL AND INSTRUMENTATION CABLES",
              titleSlug: 'power-control-instrumentation-cables',
              types: [
                {
                  name: "LT/MV PVC/XLPE POWER CABLES",
                  slug: "lt-power-cables-up-to-11-kv",
                },
                {
                  name: "LT/MV PVC/XLPE Control Cables",
                  slug: "control-cables",
                },
                {
                  name: "Instrumentation/Signal Cables",
                  slug: "instrumental-cables",
                },
                {
                  name: "Fire Survival Cables",
                  slug: "low-smoke-zero-halogen-cables-lt",
                },
                {
                  name: "Railway Signalling Cables",
                  slug: "railway-signaling-cables",
                },
              ],
            },
            {
              name: "HOUSE/INDUSTRIAL WIRING AND FLEXIBLE CABLES",
              titleSlug:'house-industrial-wiring-and-flexible-cables',
              types: [
                {
                  name: "Single Core and Multicore Flexible Cables",
                  slug: "single-core-flexible-wires",
                },
                {
                  name: "Submersible Flat Cables",
                  slug: "pvc-insulated-submersible-flat-cables",
                },
                {
                  name: "LSZH Cables",
                  slug: "low-smoke-zero-halogen-cables-house",
                },
                {
                  name: "Photovoltaic (PV) Solar Cables",
                  slug: "solar-pv-dc-cables",
                },
              ],
            },
            {
              name: "SUPER ENAMELLED WINDING WIRES",
              titleSlug: 'enameled-wires',
              types: [
                {
                  name: "Enameled Wires",
                  slug: "enameled-wires",
                },
              ],
            },
          ],
        },
        { title: "Sectors", id: "focus" },
      ],
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  scrollToSection = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  handleScroll() {
    const value = window.scrollY;
    if (value > 100) {
      document.querySelector(".header--fixed").classList.add("sticky");
    } else {
      document.querySelector(".header--fixed").classList.remove("sticky");
    }
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  stickyHeader() {}

  render() {
    const { home, contact } = this.props;

    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { logo, color = "default-color" } = this.props;
    let logoUrl;
    if (logo === "light") {
      logoUrl = (
        <img src="/assets/images/logo/logo-light.svg" alt="Digital Agency" />
      );
    } else if (logo === "dark") {
      logoUrl = (
        <img src="/assets/images/logo/logo-dark.svg" alt="Digital Agency" />
      );
    } else if (logo === "symbol-dark") {
      logoUrl = (
        <img src="/assets/images/logo/logo-symbol-dark.svg" alt="Digital Agency" />
      );
    } else if (logo === "symbol-light") {
      logoUrl = <img src="/assets/images/logo/logo_original.svg" alt="BMI" />;
    } else {
      logoUrl = <img src="/assets/images/logo/logo.svg" alt="Digital Agency" />;
    }

    const links = ["about", "service", "products", "focus", "testimonial"];

    return (
      <header className={`header-area header-style-two header--fixed ${home ? 'home' : ''} ${color}`}>
        <div className={`header-wrapper ${home ? "home" : ""}`}>
          <div className="header-left d-flex align-items-center">
            <div className="logo">
              <Link to={this.props.homeLink}>{logoUrl}</Link>
            </div>
            {home ? (
              <nav className="mainmenunav d-lg-block ml--50">
                <ul className="mainmenu">
                  <li>
                      <Link to="/">Home</Link>
                  </li>
                  {this.state.navLinks.map((link) =>
                    link.id === "products" ? (
                      <li className="has-droupdown" key={link.id}>
                        <a onClick={() => this.scrollToSection(link.id)}>
                          {link.title}
                        </a>
                        <ul className="submenu active">
                        {link.type.map((x) => (
                          x.titleSlug === 'enameled-wires' ? <li><Link style={{ textDecoration: "none" }} to={`/product/details/${x.titleSlug}`}>{x.name}</Link></li> :
                          <li>
                            <Link to={`/product/${x.titleSlug}`} style={{ textDecoration: "none" }}>{x.name}</Link>
                            <ul className="submenu">
                              {x.types.map((a) => (
                                <li>
                                  <Link to={`/product/details/${a.slug}`}>
                                    {a.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                      </li>
                    ) : link.id === "aboutUs" ? (
                        <li className="has-droupdown" key={link.id}>
                          <a onClick={() => this.scrollToSection(link.id)}>
                            {link.title}
                          </a>
                          <ul className="submenu active">
                            {link.type.map((x) => (
                              <li key={x.name}>
                                <HashLink to={`/about#${x.id}`} style={{ textDecoration: "none" }}>{x.name}</HashLink>
                              </li>
                            ))}
                          </ul>
                        </li>
                      )  : (
                      <>
                        <li key={link.id}>
                          <a onClick={() => this.scrollToSection(link.id)}>
                            {link.title}
                          </a>
                        </li>
                        <li>
                          <Link to="/Customer">Customers</Link>
                        </li>
                      </>
                    )
                  )}
                </ul>
              </nav>
            ) : (
              <nav className="mainmenunav d-lg-block ml--50">
              <ul className="mainmenu">
                <li>
                      <Link to="/">Home</Link>
                  </li>
                {this.state.navLinks.map((link) =>
                  link.id === "products" ? (
                    <li className="has-droupdown" key={link.id}>
                      <a>
                        {link.title}
                      </a>
                      <ul className="submenu active">
                        {link.type.map((x) => (
                          x.titleSlug === 'enameled-wires' ? <li><Link style={{ textDecoration: "none" }} to={`/product/details/${x.titleSlug}`}>{x.name}</Link></li> :
                          <li>
                            <Link to={`/product/${x.titleSlug}`} style={{ textDecoration: "none" }}>{x.name}</Link>
                            <ul className="submenu">
                              {x.types.map((a) => (
                                <li>
                                  <Link to={`/product/details/${a.slug}`}>
                                    {a.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ) : link.id === "aboutUs" ? (
                      <li className="has-droupdown" key={link.id}>
                        <Link to='/about' style={{ textDecoration: "none" }}>
                          {link.title}
                        </Link>
                        <ul className="submenu active">
                          {link.type.map((x) => (
                            <li key={x.name}>
                              <HashLink to={`/about#${x.id}`} style={{ textDecoration: "none" }}>{x.name}</HashLink>
                            </li>
                          ))}
                        </ul>
                      </li>
                    )  : (
                      <>
                      <li>
                        <Link to="/Customer">Customers</Link>
                      </li>
                    </>
                  )
                )}
              </ul>
            </nav>
            )}
          </div>
          <div className="header-right">
            {!contact ? (
              <div className="header-btn">
                <Link className="rn-btn" to="/contact">
                  <span>Contact Us</span>
                </Link>
              </div>
            ) : (
              <></>
            )}
              <>
                {/* Start Humberger Menu  */}
                <div className="humberger-menu d-block d-lg-none pl--20">
                  <span
                    onClick={this.menuTrigger}
                    className="menutrigger text-white"
                  >
                    <FiMenu />
                  </span>
                </div>
                {/* End Humberger Menu  */}
                <div className="close-menu d-block d-lg-none">
                  <span
                    onClick={this.CLoseMenuTrigger}
                    className="closeTrigger"
                  >
                    <FiX />
                  </span>
                </div>
              </>
          </div>
        </div>
      </header>
    );
  }
}

/*
 * EXPORTS
 */
export default HeaderThree;

{
  /* <li key={link.id}>
       <a onClick={() => this.scrollToSection(link.id)}>{link.title}</a>
      </li> */
}
