import React from 'react';
import {Link} from 'react-router-dom'

const FooterTwo = () => {
    return (
        <div className="footer-style-2 ptb--0" data-white-overlay="1">
            <div className="wrapper plr--50 plr_sm--20">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner">
                            <div className="logo text-center text-sm-left mb_sm--20">
                                <Link to="/">
                                    <img src="/assets/images/logo/logo.png" alt="Logo images"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
                            <div className="text">
                                <p className='copyright'>Copyright © 2023 BMI Cables. Developed By <a className='developedByCompanyName' href='www.rootandleaves.com'>Rootandleaves</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            

        </div>



    )
}
export default FooterTwo;
