// import React, { Component } from "react";
// import ContactForm from "./ContactForm";

// class ContactTwo extends Component {
//     render(){
//         return(
//             <div className="contact-form--1">
//                 <div className="container">
//                     <div className="row row--35 align-items-start">
//                         <div className="col-lg-6 order-2 order-lg-1">
//                             <div className="section-title text-left mb--50">
//                                 <h2 className="title">Enquiry</h2>
//                                 <p className="description">If you have any questions or comments about our products and services, please feel free to reach out to us.</p>
//                             </div>
//                             <div className="form-wrapper">
//                                 <ContactForm />
//                             </div>
//                         </div>
//                         <div className="col-lg-6 order-1 order-lg-2">
//                             <div className="thumbnail mb_md--30 mb_sm--30">
//                                 <img src="/assets/images/contact/form.png" alt="bmi"/>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )
//     }
// }
// export default ContactTwo;


import React, { Component } from "react";
import ContactForm from "./ContactForm";

class ContactTwo extends Component {
    render() {
        return (
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 justify-content-center">
                        <div className="col-lg-10">
                            <div className="section-title text-center mb--50">
                                <h2 className="title">Enquiry</h2>
                                <p className="description">
                                    If you have any questions or comments about our products and services, please feel free to reach out to us.
                                </p>
                            </div>
                            <div className="form-wrapper">
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactTwo;
