import React, { Component } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";

import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";
import PageHelmet from "../component/common/Helmet";
import Brand from "../elements/Brand";

class Customer extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }

  openModal = () => {
    this.setState({ isOpen: true });
  };

  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Customer" />

        {/* Header Area */}
        <HeaderThree homeLink="/" logo="symbol-light" color="color-black" />

        {/* Breadcrump Area with Background Image */}
        <div className="rn-page-title-area pt--120 pb--190" style={{ backgroundImage: "url('/assets/images/bg/customers.jpg')", backgroundSize: "cover", backgroundPosition: "center",  }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Our Customers</h2>
                  {/* <p>Trusted by many</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Brand Section */}
        <div className="rn-page-title-area pt--120 pb--190">
          <Brand />
        </div>

        {/* Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>

        {/* Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Customer;
