
// import React, { Component } from "react";

// class Brand extends Component{
//     render(){
//         const {branstyle } = this.props;
//         return(
//             <React.Fragment>
//                 <ul className={`brand-list ${branstyle}`}>
//                     <li>
//                         <img src="/assets/images/brand/brand-01.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-02.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-03.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-04.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-05.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-06.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-07.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-08.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-09.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-10.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-11.svg" alt="Logo Images"/>
//                     </li>
//                     {/* <li>
//                         <img src="/assets/images/brand/brand-12.svg" alt="Logo Images"/>
//                     </li> */}
//                     {/* <li>
//                         <img src="/assets/images/brand/brand-13.svg" alt="Logo Images"/>
//                     </li> */}
//                     <li>
//                         <img src="/assets/images/brand/brand-14.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-15.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-16.svg" alt="Logo Images"/>
//                     </li>
//                     {/* <li>
//                         <img src="/assets/images/brand/brand-17.svg" alt="Logo Images"/>
//                     </li> */}
//                     {/* <li>
//                         <img src="/assets/images/brand/brand-18.svg" alt="Logo Images"/>
//                     </li> */}
//                     <li>
//                         <img src="/assets/images/brand/brand-19.svg" alt="Logo Images"/>
//                     </li>
//                     {/* <li>
//                         <img src="/assets/images/brand/brand-20.svg" alt="Logo Images"/>
//                     </li> */}
//                     <li>
//                         <img src="/assets/images/brand/brand-22.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-23.svg" alt="Logo Images"/>
//                     </li>
//                     {/* <li>
//                         <img src="/assets/images/brand/brand-24.svg" alt="Logo Images"/>
//                     </li> */}
//                     {/* <li>
//                         <img src="/assets/images/brand/brand-25.svg" alt="Logo Images"/>
//                     </li> */}
//                     {/* <li>
//                         <img src="/assets/images/brand/brand-26.svg" alt="Logo Images"/>
//                     </li> */}
//                     <li>
//                         <img src="/assets/images/brand/brand-27.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-28.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-29.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-30.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-31.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-32.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-33.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-34.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-35.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-36.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-37.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-38.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-39.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-40.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-42.svg" alt="Logo Images"/>
//                     </li>
//                     <li>
//                         <img src="/assets/images/brand/brand-43.svg" alt="Logo Images"/>
//                     </li>
                
//                 </ul>
//             </React.Fragment>
//         )
//     }
// }
// export default Brand;



import React, { Component } from "react";

class Brand extends Component {
    render() {
        const brandImages = [
            "brand-01.svg", "brand-02.svg", "brand-03.svg", "brand-04.svg", "brand-05.svg",
            "brand-06.svg", "brand-07.svg", "brand-08.svg", "brand-09.svg", "brand-10.svg",
            "brand-11.svg", "brand-14.svg", "brand-15.svg", "brand-16.svg", "brand-19.svg",
            "brand-22.svg", "brand-23.svg", "brand-27.svg", "brand-28.svg", "brand-29.svg",
            "brand-30.svg", "brand-31.svg", "brand-32.svg", "brand-33.svg", "brand-34.svg",
            "brand-35.svg", "brand-36.svg", "brand-37.svg", "brand-40.svg", "brand-42.svg", 
            "brand-43.svg"
        ];

        return (
            <div className="container py-4">
                <div className="row g-3">
                    {brandImages.map((image, index) => (
                        <div key={index} className="col-6 col-md-3 col-lg-2 d-flex justify-content-center align-items-center border p-4 bg-white rounded">
                            <img
                                src={`/assets/images/brand/${image}`}
                                alt={`Logo ${index + 1}`}
                                style={{ maxHeight: "100px", maxWidth: "100%" }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default Brand;
