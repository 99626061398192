/*
 * IMPORTS
 */
import React from "react";
import ScrollToTop from "react-scroll-up";
import Slider from "react-slick";
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

/*
 * COMPONENTS
 */
import { slideSlick } from "../page-demo/script";
import ServiceList from "../elements/service/ServiceList";
import BrandTwo from "../elements/BrandTwo";
import FooterTwo from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";
import TeamTwo from "../blocks/team/TeamTwo";

/*
 * Const Assignment
 */
const SlideList = [
  {
    bgImage: "bg_image--17",
    // textPosition: '',
    // category: '',
    // title: '',
    // description: ''
  },
];

const _Certificate = [
  {
    image: "/assets/images/certificate/1.webp",
    title: "ISO 9001 : 2015",
  },
  {
    image: "/assets/images/certificate/10.webp",
    title: "BIS 694-2010",
  },
  {
    image: "/assets/images/certificate/8.webp",
    title: "BIS 1554 Part-1",
  },
  {
    image: "/assets/images/certificate/9.webp",
    title: "BIS 1554 Part-II",
  },
  {
    image: "/assets/images/certificate/13.webp",
    title: "BIS 7098 Part-I",
  },
  {
    image: "/assets/images/certificate/11.webp",
    title: "BIS 9968 Part-I",
  },
  {
    image: "/assets/images/certificate/12.webp",
    title: "BIS 14494 2019",
  },
];

/*
 * Class Component
 */
const Business = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [view, setView] = React.useState(3);

  const openModal = (index) => setIsOpen(index);

  const closeModal = () => setIsOpen(false);

  const namesItemOne = [
    "BMI is a leading manufacturer of Electrical Cables and Wires, having corporate office in New Delhi and manufacturing unit in Bhiwadi, Rajasthan and is group company of ESS ELL cable group. BMI is ISO 9001:2015 certified company, with advanced production lines and quality control facilities. Our integrated plant produces high-quality cables meeting global standards. We also provide customized cables to meet specific customer requirements. The quality control program of BMI Cables includes a well-equipped Testing Laboratory to test incoming and finished products at every stage. Our products have passed rigorous in-house and third-party testing. BMI is one of the leading manufacturers of specialty cables and trusted by industries such as Oil & Gas, Steel & Power, Automotive, Telecome, Agriculture and Home Appliances.",
  ];

  return (
    <div>
      <HeaderThree homeLink="/" logo="symbol-light" color="color-black" />
      {/* Start Slider Area   */}
      <div className="slider-wrapper">
        <div className="slider-activation">
          <Slider className="rn-slick-dot dot-light" {...slideSlick}>
            {SlideList.map((value, index) => (
              <div
                className={`slide slide-style-2 d-flex align-items-center pt-7 justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="7">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 mr-5 ml-5">
                      <div className="rn-page-title text-center pt--100">
                        <h2 className="title theme-gradient">About Us</h2>
                        {/* <p>Trusted by many</p> */}
                      </div>
                      {/* <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ''}
                      {value.title ? <h1 className="title">{value.title}</h1> : ''}
                      {value.description ?
                          <p style={{'height':'20px'}} className="description">{value.description}</p> : ''}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      {/* End Slider Area   */}

      <div className="rn-about-area ptb--120 bg_color--1">
        <div className="rn-about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              {/* <div className="col-lg-5">
                <div className="thumbnail">
                    <img className="w-100" src="/assets/images/cables/insulatedCables3.png" alt="About Images"/>
                </div>
            </div> */}
              <div className="col-lg-12 text-center">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title ">About Us</h2>
                  </div>
                  <div className="row mt--30">
                    {/* <div>
                      <ul className="list-style--1">
                        {namesItemOne.map((name, index) => {
                          return <li key={index}> {name}</li>;
                        })}
                      </ul>
                    </div> */}
                    <div class="section-title text-center justify-content-center">
                        {namesItemOne.map((name, index) => {
                            return <p key={index}> {name}</p>;
                          })}
                        </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Start Service Area  */}
      <div id="kld" className="fix">
        <div id="kl" className="service-area creative-service-wrapper ptb--120 bg_whyus _bg_color--2" >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
                  <h2 className="title text-light">Why Us</h2>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              <div className="col-lg-12">
                <ServiceList
                  item="6"
                  column="col-lg-4 col-md-6 col-sm-6 col-12 text-left h-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Service Area  */}

      {/* Team Page starts  */}
      <div id="our-team" className="service-area creative-service-wrapper ptb--100 bg_color--1" >
        <div className="container ">
          <div className="h1 align-items-center text-center mb-5">Our Team</div>
          <div className="row align-items-center justify-content-center">
            <TeamTwo column="align-items-center p-4" />
          </div>
        </div>
      </div>
      {/* Team Page Ends Here */}

      <div
        id="certifications"
        className="rn-blog-area pt--120 pb--80 bg_color--5 certificate"
      >
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12">
              <div className="section-title service-style--3 text-center">
                <h2 className="title">Certifications</h2>
              </div>
            </div>
          </div>

          {/* Start Certificate Area */}
          <div className="row mt--60 justify-content-center">
            {_Certificate.slice(0, view).map((value, i) => (
              <>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => openModal(i)}
                  className="col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <div className="blog blog-style--1 border border-secondary">
                    <div className="thumbnail">
                      <img
                        className="w-100"
                        src={value.image}
                        alt="Certificate"
                      />
                    </div>
                    <div className="content">
                      <p className="blogtype">{value.title}</p>
                    </div>
                  </div>
                </div>

                {isOpen === i ? (
                  <Lightbox
                    mainSrc={value.image}
                    onCloseRequest={() => closeModal()}
                    imageCaption="My Image"
                    images={[value.image]} // pass an array with one element
                  />
                ) : (
                  ""
                )}
              </>
            ))}
          </div>
          <div id="our-customers" className="d-flex justify-content-center">
            {view === _Certificate.length ? (
              <button
                onClick={() => setView(3)}
                className="rn-button-style--2 btn-solid"
              >
                View Less
              </button>
            ) : (
              <button
                onClick={() => setView(_Certificate.length)}
                className="rn-button-style--2 btn-solid"
              >
                View More
              </button>
            )}
          </div>
        </div>
      </div>
      {/* End Certificate Area */}

      {/* Start Brand Area */}

      <div className="rn-brand-area bg_color--1 ptb--60">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <BrandTwo />
            </div>
          </div>
        </div>
      </div>
      {/* End Brand Area */}

      {/* Start Footer Style  */}
      <FooterTwo />
      {/* End Footer Style  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

/*
 EXPORTS
 */
export default Business;
